import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from '@vuer-ai/react-helmet-async';
// import { ClickAwayListener, useSnackbar } from '@mui/base';
// import {
//   InfoSnackbar,
//   Snackbars,
//   SuccessSnackbar,
//   WarningSnackbar,
// } from './_components/snackbars';
import style from './index.module.scss';
import {
  AppProvider,
  Grid,
  Hands,
  HemisphereLightStage,
  VuerRoot,
  WebSocketProvider,
} from '@vuer-ai/vuer';
import ReactDOM from 'react-dom';
import * as THREE from 'three';
import * as JSX from 'react/jsx-runtime';
import * as FIBER from '@react-three/fiber';
import * as VUER from '@vuer-ai/vuer';
import * as Drei from '@react-three/drei';
import * as XR from '@react-three/xr';

declare global {
  interface Window {
    React: typeof React;
    ReactDOM: typeof ReactDOM;
    VUER: typeof VUER;
    THREE: typeof THREE;
    JSX: typeof JSX;
    FIBER: typeof FIBER;
    XR: typeof XR;
    Drei: typeof Drei;
  }
}

if (typeof window !== 'undefined') {
  window.React = React;
  window.ReactDOM = ReactDOM;
  window.VUER = VUER;
  window.THREE = THREE;
  window.JSX = JSX;
  window.FIBER = FIBER;
  window.XR = XR;
  window.Drei = Drei;
}

type MTypes = 'error' | 'warning' | 'success' | 'info';
type StateT = {
  message?: string | null;
  mtype?: MTypes | null;
};

function AppView() {
  return (
    <VuerRoot
      bgChildren={[
        <Grid key='default-grid' />,
        // <AmbientLight intensity={1} key='default-ambient-light' />,
        <HemisphereLightStage key='staging' />,
        // <Movable
        //   key="point-light-anchor-1"
        //   position={[ 0, 2, 0 ]}
        // >
        //   <PointLight
        //     intensity={1}
        //     key="point-light"
        //   />
        // </Movable>,
      ]}
    >
      <Hands key='hands' />
    </VuerRoot>
  );
}

function Page() {
  const isSSR = typeof window === 'undefined';
  const [{ message, mtype }, setState] = useState({
    message: null,
    mtype: null,
  } as StateT);

  // const { getRootProps, onClickAway } = useSnackbar({
  //   onClose: () => {
  //     setState({ message: null, mtype: null });
  //   },
  //   open: !!message,
  //   autoHideDuration: 5000,
  // });

  useEffect(() => {
    const oldError = console.error;
    // console.error = (err) => {
    //   setTimeout(() => {
    //     setState({ message: err, mtype: "error" });
    //     console.log(err);
    //   }, 1)
    // };
    return () => {
      console.error = oldError;
    };
  }, []);

  useEffect(() => {
    const oldWarn = console.warn;
    console.warn = (err) => {
      setTimeout(() => {
        setState({ message: err, mtype: 'warning' });
        console.log(err);
      }, 1);
    };
    return () => {
      console.warn = oldWarn;
    };
  }, []);

  const context = useMemo(
    () => ({
      showError: (message: string) =>
        setTimeout(() => setState({ message, mtype: 'error' }), 1),
      showSuccess: (message: string) =>
        setTimeout(() => setState({ message, mtype: 'success' }), 1),
      showWarning: (message: string) =>
        setTimeout(() => setState({ message, mtype: 'warning' }), 1),
      showInfo: (message: string) =>
        setTimeout(() => setState({ message, mtype: 'info' }), 1),
      showModal: (message: string) => {
        console.log(message);
      },
    }),
    [],
  );

  // function render({ message, mtype }: StateT) {
  //   switch (mtype) {
  //     case 'error':
  //       return <Snackbars {...getRootProps()}>ERROR: {message}</Snackbars>;
  //     case 'warning':
  //       return (
  //         <WarningSnackbar {...getRootProps()}>
  //           WARNING: {message}
  //         </WarningSnackbar>
  //       );
  //     case 'success':
  //       return (
  //         <SuccessSnackbar {...getRootProps()}>
  //           SUCCESS: {message}
  //         </SuccessSnackbar>
  //       );
  //     case 'info':
  //     default:
  //       return <InfoSnackbar {...getRootProps()}>INFO: {message}</InfoSnackbar>;
  //   }
  // }

  // if (isSSR) return null;
  return (
    <>
      <Helmet>
        <title>Vuer (Technical Preview)</title>
      </Helmet>
      <main className={style.main}>
        {/*{message ? (*/}
        {/*  <ClickAwayListener onClickAway={onClickAway}>*/}
        {/*    {render({ message, mtype })}*/}
        {/*  </ClickAwayListener>*/}
        {/*) : null}*/}
        {/*<Suspense fallback={<div>Loading...</div>}>*/}
        <AppProvider value={context}>
          {!isSSR ? (
            <WebSocketProvider>
              <AppView />
            </WebSocketProvider>
          ) : null}
        </AppProvider>
        {/*</Suspense>*/}
      </main>
    </>
  );
}

export default Page;
